import React, { useState, useEffect, useContext } from "react";
import KitaContext from "../KitaContext";
import { auth } from "../firebase-config";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import '../styles/AttendanceList.css';

const CalculateAttendance = ({ date }) => {
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedKita } = useContext(KitaContext);
  const typeRefs = {};
  const groupRefs = {};
  const commentRefs = {};
  const attendingRefs = {};
  const reasonRefs = {};
  const [isDayClosed, setIsDayClosed] = useState(false);

  useEffect(() => {
    const calculateAttendance = async () => {
      setIsLoading(true);
      try {
        if (auth.currentUser && selectedKita) {
          const idToken = await auth.currentUser.getIdToken();
          const baseUrl =
            window.location.hostname === "localhost"
              ? "http://localhost:5000"
              : "https://smartkita-backend-0fea91c0e62d.herokuapp.com";

          const response = await fetch(
            `${baseUrl}/calculate-attendance/${selectedKita.id}?timestamp=${date}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            const responseData = await response.json();
            //console.log(responseData);

            if (
              responseData.combinedChildrenListForCalculatedAndCheckedAttendance &&
              Array.isArray(
                responseData.combinedChildrenListForCalculatedAndCheckedAttendance
              )
            ) {
              const processedData = responseData.combinedChildrenListForCalculatedAndCheckedAttendance.map(
                (child) => ({
                  ...child,
                  isAttending: child.isAttending === null ? false : child.isAttending,
                  groupRef: child.groupRef || {},
                  // Hinzufügen von weiteren Datenverarbeitungen hier, falls notwendig
                })
              );
              setChildren(processedData);
            }
          } else {
            console.error("Fehler beim Abrufen der Anwesenheitsdaten");
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    calculateAttendance();
  }, [date, selectedKita]);

  const handleSave = async (childId, child) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl =
        window.location.hostname === "localhost"
          ? "http://localhost:5000"
          : "https://smartkita-backend-0fea91c0e62d.herokuapp.com";

      // Holen Sie sich die Werte aus den Formularelementen über refs
      const attendanceType = typeRefs[childId]?.current?.value || "";
      const groupValue = groupRefs[childId]?.current?.value || "";
      const commentValue = commentRefs[childId]?.current?.value || "";
      const isAttendingValue = attendingRefs[childId]?.current?.checked || false;
      const reasonValue = reasonRefs[childId]?.current?.value || "";

      const payload = {
        firstname: child.firstname,
        lastname: child.lastname,
        attendance_type: attendanceType,
        changed_at: new Date().toISOString(),
        changed_by: auth.currentUser.uid,
        childRef: `/child/${childId}`,
        comment: commentValue,
        created_at: new Date().toISOString(),
        created_by: auth.currentUser.uid,
        groupRef: `/kita/${selectedKita.id}/group/${groupValue}`,
        isAttending: isAttendingValue,
        isSick: reasonValue === "isSick",
        inHoliday: reasonValue === "inHoliday",
        givenDate: date.toISOString(),
      };
      //console.log(payload);

      const response = await fetch(
        `${baseUrl}/save-attendance/${selectedKita.id}/${childId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        // Hervorheben der erfolgreichen Zeile
        const updatedChildren = children.map((c) => {
          if (c.id === child.id) {
            // Hier fügen Sie eine Eigenschaft hinzu, um anzuzeigen, dass die Zeile erfolgreich gespeichert wurde
            return { ...c, isSavedSuccessfully: true };
          }
          return c;
        });

        setChildren(updatedChildren);
    
      } else {
        throw new Error(`Fehler beim Speichern der Daten: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    }
  };

  const handleClosingDay = async () => {
    try {
      if (auth.currentUser && selectedKita) {
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl =
          window.location.hostname === "localhost"
            ? "http://localhost:5000"
            : "https://smartkita-backend-0fea91c0e62d.herokuapp.com";
  
        // Überprüfen Sie, ob alle Kinder eingecheckt sind
        const allChildrenCheckedIn = children.every((child) => child.checkin);
  
        if (allChildrenCheckedIn) {
          const payload = {
            created_at: new Date().toISOString(),
            created_by: auth.currentUser.uid,
            givenDate: date.toISOString(),
            dailyClosing: true,
          };
          // Hier den Backend-Aufruf für den Tagesabschluss durchführen
          const response = await fetch(
            `${baseUrl}/close-attendance/${selectedKita.id}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );
  
          if (response.ok) {
            // Tagesabschluss erfolgreich
            alert(
              `Tag wurde am ${new Date().toLocaleString()} abgeschlossen.`
            );
            setIsDayClosed(true); 
          } else {
            // Tagesabschluss fehlgeschlagen
            throw new Error(`Fehler beim Tagesabschluss: ${response.status}`);
          }
        } else {
          alert("Tagesabschluss kann nur durchgeführt werden, wenn alle Kinder eingecheckt sind.");
        }
      }
    } catch (error) {
      console.error(error);
      alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    }
  };
  
  

  return (
    <div>
      <p>Berechnung für das Datum: {date.toLocaleDateString("de-DE")}</p>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Laden...</span>
        </Spinner>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Kind</th>
              <th>Typ</th>
              <th>Gruppe</th>
              <th>Anwesend</th>
              <th>Grund</th>
              <th>Kommentar</th>
            </tr>
          </thead>
          <tbody>
            {children.map((child) => {
              const groupSegment =
              child.checkin
                ? (child.groupRef &&
                    child.groupRef._path &&
                    child.groupRef._path.segments &&
                    child.groupRef._path.segments[3]) ||
                  "N/A"
                : (child.childcareData[0].groupRef &&
                    child.childcareData[0].groupRef._path &&
                    child.childcareData[0].groupRef._path.segments &&
                    child.childcareData[0].groupRef._path.segments[3]) ||
                  "N/A";

              // Zustandsänderungs-Funktion
              const handleToggleChange = (e) => {
                const updatedState = e.target.checked;
                const updatedChildren = children.map((c) => {
                  if (c.id === child.id) {
                    return { ...c, isAttending: updatedState };
                  }
                  return c;
                });
                setChildren(updatedChildren);
              };
              // Zustandsänderungs-Funktion für den Grund-Dropdown
              const handleReasonChange = (e) => {
                const newValue = e.target.value;
                const updatedChildren = children.map((c) => {
                  if (c.id === child.id) {
                    // Aktualisieren Sie den Grundwert basierend auf der ausgewählten Option
                    return {
                      ...c,
                      isSick: newValue === "isSick",
                      inHoliday: newValue === "inHoliday",
                    };
                  }
                  return c;
                });
                setChildren(updatedChildren);
              };
              // Ermitteln Sie den Wochentag mit dem ersten Buchstaben in Großbuchstaben
              const dayOfWeek = new Date(date).toLocaleDateString("en-US", {
                weekday: "long",
              });

              // Zugriff auf das Attribut in child.childcareData[0] mit dem entsprechenden Schlüsselnamen oder direkt aus den eingecheckten kindern den wert attendance_type
              const typeValue = child.checkin
                ? child.attendance_type
                : child.childcareData[0]["type" + dayOfWeek];


                // Erstelle refs für dieses spezifische Kind
                if (!typeRefs[child.id]) {
                    typeRefs[child.id] = React.createRef();
                    groupRefs[child.id] = React.createRef();
                    commentRefs[child.id] = React.createRef();
                    attendingRefs[child.id] = React.createRef();
                    reasonRefs[child.id] = React.createRef();
                }

              return (
                <tr
                key={child.id}
                data-checkin={child.checkin || child.isSavedSuccessfully}
              >
                  <td>
                    {child.firstname} {child.lastname}
                  </td>
                  <td>
                  <Form.Control 
                      ref={typeRefs[child.id]}
                      as="select" 
                      defaultValue={typeValue}>
                      <option value="fullday">Ganzer Tag</option>
                      <option value="halfday">Halber Tag</option>
                    </Form.Control>
                  </td>
                  <td>
                    <Form.Control 
                      ref={groupRefs[child.id]}
                      as="select" 
                      defaultValue={groupSegment}>
                      {selectedKita.groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                  <Form.Check
                    type="switch"
                    id={child.id}
                    ref={attendingRefs[child.id]}
                    label={child.isAttending ? "Ja" : "Nein"} // Label basierend auf isAttending
                    defaultValue={false}
                    checked={child.isAttending}
                    onChange={handleToggleChange}
                  />
                  </td>
                  <td>
                    {!child.isAttending ? (
                      <Form.Control
                      ref={reasonRefs[child.id]}
                      as="select"
                      value={
                        child.isSick
                          ? "isSick"
                          : child.inHoliday
                          ? "inHoliday"
                          : ""
                      }
                      onChange={handleReasonChange} // Hinzugefügte Änderung: onChange-Handler
                    >
                      <option value="">Bitte auswählen</option>
                      <option value="isSick">Krankheit</option>
                      <option value="inHoliday">Ferien</option>
                    </Form.Control>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                  <Form.Control 
                      ref={commentRefs[child.id]}
                      type="text" 
                      defaultValue={child.comment}
                      placeholder="Kommentar"
                    />
                  </td>
                  <td>
                    <Button variant="outline-primary" onClick={() => handleSave(child.id, child)}>
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <Button
        variant="primary"
        className={`float-right ${isDayClosed ? 'd-none' : ''}`}
        onClick={handleClosingDay}
        >
        Tagesabschluss
      </Button>

    </div>
  );
};

export default CalculateAttendance;
