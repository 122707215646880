import React, { useEffect, useState, useContext, useCallback } from 'react';
import KitaContext from "../KitaContext";
import { auth } from "../firebase-config";
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun as faSunSolid,
  faMoon as faMoonSolid,
  faPlus,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import '../styles/ChildCareInfo.css';

const ChildBetreuungszeiten = ({ childId }) => {
  const { selectedKita } = useContext(KitaContext);
  const [showModal, setShowModal] = useState(false);
  const [childData, setChildData] = useState(null);

  const [formState, setFormState] = useState({
    start: null,
    end: null,
    groupRef: ''
  });

  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  
  const [selectedDayTypes, setSelectedDayTypes] = useState({
    typeMonday: '',
    typeTuesday: '',
    typeWednesday: '',
    typeThursday: '',
    typeFriday: '',
    typeSaturday: '',
    typeSunday: '',
  });

  const today = new Date();
  const todayFormatted = `${today.getDate().toString().padStart(2, '0')}.${(today.getMonth() + 1).toString().padStart(2, '0')}.${today.getFullYear()}`;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChildcare, setIsLoadingChildcare] = useState(true);
  const [childcareDataExists, setChildcareDataExists] = useState(false);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [editMode, setEditMode] = useState(false);

  const fetchChildCareData  = useCallback(async () => {
    try {
      setIsLoadingChildcare(true);
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

        const response = await fetch(`${baseUrl}/childcare/${childId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });

        if (response.ok) {
          const childCareData = await response.json();
          setChildData(childCareData);
          console.log(childCareData);
          // Überprüfe, ob childCareData.childcareData nicht leer ist
          if (Array.isArray(childCareData.childcareData) && childCareData.childcareData.length > 0) {
            setChildcareDataExists(true);
          }
        } else {
          console.error('Error fetching child data');
        }
      }
    } catch (error) {
      console.error(error);
      setChildcareDataExists(false);
    } finally {
      setIsLoadingChildcare(false);
    }
  }, [childId]);

  useEffect(() => {
    fetchChildCareData();
  }, [fetchChildCareData]);

  // Funktion zur Formatierung der Timestamps
  const formatDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000); // Konvertiert den Timestamp in Millisekunden
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1, da Monate von 0-11 gezählt werden
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const days = [
    { name: 'Montag', type: 'typeMonday', isActiveDay: 'monday' },
    { name: 'Dienstag', type: 'typeTuesday', isActiveDay: 'tuesday' },
    { name: 'Mittwoch', type: 'typeWednesday', isActiveDay: 'wednesday' },
    { name: 'Donnerstag', type: 'typeThursday', isActiveDay: 'thursday' },
    { name: 'Freitag', type: 'typeFriday', isActiveDay: 'friday' },
   // { name: 'Samstag', type: 'typeSaturday', isActiveDay: 'saturday' },
   // { name: 'Sonntag', type: 'typeSunday', isActiveDay: 'sunday' },
  ];

    // Funktion zum Rendern einer einzelnen Betreuungskachel
    const renderBetreuungKachel = (day) => {
      const isActiveDay = childData.childcareData[0][day.isActiveDay];
      const betreuungTyp = childData.childcareData[0][day.type];

      // Wenn der Tag nicht aktiv ist, nichts rendern
      if (!isActiveDay) {
        return null;
      }

      // Hintergrundfarben und Icons entsprechend des Betreuungstyps festlegen
      let bgColor = '';
      let icon = null;

      if (betreuungTyp === 'fullday') {
        bgColor = 'gold';
        icon = faSunSolid;
      } else if (betreuungTyp === 'halfday') {
        bgColor = 'lightyellow';
        icon = faMoonSolid;
      }

      return (
        <div
          key={day.name}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: bgColor,
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            textAlign: 'center',
            width: 'calc(33% - 10px)', // Breite für 3 nebeneinander
            marginRight: '10px', // Abstand zwischen den Kacheln
            flex: '0 0 auto', // Verhindert, dass die Kacheln in der Breite wachsen
          }}
        >
          <div>{day.name}</div>
          <div>
            <FontAwesomeIcon icon={icon || faSunSolid} style={{ marginRight: '5px' }} />
          </div>
          {betreuungTyp === 'fullday' && <div>Ganztag</div>}
          {betreuungTyp === 'halfday' && <div>Halbtag</div>}
        </div>
      );
    };



    const handleEdit = () => {
      console.log("Edit button clicked");
      setEditMode(true);
      console.log("EditMode set to true");
      setShowModal(true);
      console.log("ShowModal set to true");
    
      // Set editedChildcareData with existing data
      if (childData && childData.childcareData && childData.childcareData.length > 0) {
        const editedChildcareData = childData.childcareData[0];
        console.log("setEditedChildcareData befüllt", editedChildcareData);

        // Umwandlung der Timestamps in Date-Objekte
    const startDate = editedChildcareData.start ? new Date(editedChildcareData.start._seconds * 1000) : null;
    const endDate = editedChildcareData.end ? new Date(editedChildcareData.end._seconds * 1000) : null;

    // Vorbefüllen der Felder im Modal
    setFormState({
      start: startDate || null,
      end: endDate || null,
      groupRef: editedChildcareData.groupRef ? editedChildcareData.groupRef._path.segments[3] : '',
    });
    console.log(formState);

    setSelectedDays({
      monday: editedChildcareData.monday || false,
      tuesday: editedChildcareData.tuesday || false,
      wednesday: editedChildcareData.wednesday || false,
      thursday: editedChildcareData.thursday || false,
      friday: editedChildcareData.friday || false,
      saturday: editedChildcareData.saturday || false,
      sunday: editedChildcareData.sunday || false,
    });

    console.log(selectedDays);
    setSelectedDayTypes({
      typeMonday: editedChildcareData.typeMonday || '',
      typeTuesday: editedChildcareData.typeTuesday || '',
      typeWednesday: editedChildcareData.typeWednesday || '',
      typeThursday: editedChildcareData.typeThursday || '',
      typeFriday: editedChildcareData.typeFriday || '',
      typeSaturday: editedChildcareData.typeSaturday || '',
      typeSunday: editedChildcareData.typeSunday || '',
    });
    console.log(selectedDayTypes);
      }
    };
    
    // Event-Handler für "Speichern"
    const handleSave = () => {
      setShowModal(true);
    };

    // Event-Handler für das Einreichen der Daten aus dem Modal
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setMessage({ type: '', content: '' });
    
      // Erstelle ein neues Objekt mit den ausgewählten Daten aus formState und selectedDays
      const newChildcareData = {
        start: formState.start || null,
        end: formState.end || null,
        groupRef: formState.groupRef !== "" ? `/kita/${selectedKita.id}/group/${formState.groupRef}` : null,
        days: {
          monday: selectedDays.monday || false,
          tuesday: selectedDays.tuesday || false,
          wednesday: selectedDays.wednesday || false,
          thursday: selectedDays.thursday || false,
          friday: selectedDays.friday || false,
          saturday: selectedDays.saturday || false,
          sunday: selectedDays.sunday || false,
        },
        types: {
          typeMonday: selectedDayTypes.typeMonday || null,
          typeTuesday: selectedDayTypes.typeTuesday || null,
          typeWednesday: selectedDayTypes.typeWednesday || null,
          typeThursday: selectedDayTypes.typeThursday || null,
          typeFriday: selectedDayTypes.typeFriday || null,
          typeSaturday: selectedDayTypes.typeSaturday || null,
          typeSunday: selectedDayTypes.typeSunday || null,
        },
      };

      if (editMode && childData.childcareData[0]) {
        // Nur im Bearbeitungsmodus, wenn childcareData existiert
        newChildcareData.childCareDocId = childData.childcareData[0].id;
        newChildcareData.changed_at = new Date();
        newChildcareData.changed_by = auth.currentUser ? auth.currentUser.uid : null;
        newChildcareData.newEntry = false;
      } else {
        // Nur im "Hinzufügen" -Modus, wenn keine childcareData vorhanden ist
        newChildcareData.created_at = new Date();
        newChildcareData.created_by = auth.currentUser ? auth.currentUser.uid : null;
        newChildcareData.isActive = true;
        newChildcareData.newEntry = true;
      }

      console.log(newChildcareData);
    
    
      try {
        if (auth.currentUser) {
          const idToken = await auth.currentUser.getIdToken();
          const baseUrl = window.location.hostname === 'localhost' 
            ? 'http://localhost:5000' 
            : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

          const response = await fetch(`${baseUrl}/add-childcare/${childData.id}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newChildcareData),
        });
    
        if (response.ok) {
          console.log(editMode ? 'Childcare data updated successfully' : 'Childcare data added successfully');
          setIsLoading(false);
          setShowModal(false);
          fetchChildCareData();
          setEditMode(false);
        } else {
          console.error('Failed to add childcare data');
        }
      }
      } catch (error) {
        console.error('There was an error submitting the form: ', error);
        setMessage({ type: 'error', content: 'Fehler beim Speichern der Daten.' });
        setIsLoading(false);
      }
    };


    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const toggleDaySelection = (day, isSelected) => {
      setSelectedDays({
        ...selectedDays,
        [day]: isSelected
      });
      // Setze den Typ auf den Standardwert, wenn der Tag deaktiviert wird
      if (!isSelected) {
        const typeKey = `type${day.charAt(0).toUpperCase()}${day.slice(1)}`;
        setSelectedDayTypes((prevSelectedDayTypes) => ({
          ...prevSelectedDayTypes,
          [typeKey]: '', // Setze den Typ auf den Standardwert
        }));
      }
    };
   
    const handleDropdownChange = (event, selectedDay) => {
      const { value } = event.target;
      // Um den Namen des Typs (z.B. "typeMonday") basierend auf dem ausgewählten Tag zu erstellen
      const typeKey = `type${selectedDay.charAt(0).toUpperCase()}${selectedDay.slice(1)}`;
      setSelectedDayTypes((prevSelectedDayTypes) => ({
        ...prevSelectedDayTypes,
        [typeKey]: value,
      }));
    };
    

    // Funktion zum Anzeigen des Modals
    const toggleModal = () => setShowModal(!showModal);

    return (
      <Card>
        {isLoadingChildcare ? (
          // Wenn isLoadingChildcare true ist, zeige den Spinner
          <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        // Wenn isLoadingChildcare false ist, zeige den Card-Inhalt
        <>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            {childcareDataExists ? (
              <div>
                <Button variant="secondary" className="me-2" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </div>
            ) : (
              <div>
                <Button variant="primary" onClick={handleSave}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                </div>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {childcareDataExists ? (
          <div>
            {/* Display childcare data when available */}
            <p>
              Betreuungsstart: {formatDate(childData.childcareData[0]?.start)}<br />
              Betreuungsende: {childData.childcareData[0]?.end ? formatDate(childData.childcareData[0]?.end) : ''}<br />
              Gruppe: {childData.childcareData[0].groupRef ? selectedKita.groups.find(group => group.id === childData.childcareData[0].groupRef._path.segments[3])?.name : 'N/A'}<br />
            </p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {days.map((day) => renderBetreuungKachel(day))}
            </div>
          </div>
        ) : (
          <div>Keine Betreuungsdaten verfügbar.</div>
        )}
      </Card.Body>
      </>
      )}
                      {/* Modal for adding new childcare contract */}
                      <Modal show={showModal} onHide={toggleModal} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>{editMode ? 'Betreuungsvertrag bearbeiten' : 'Betreuungsvertrag hinzufügen'}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {isLoading && <div className="text-center"><Spinner animation="border" /></div>}
                    {message.content && (
                      <div className={`alert ${message.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
                        {message.content}
                      </div>
                    )}
                    <Form onSubmit={handleSubmit}>
                      {/* Hier kommen die Formularfelder */}
                      <Form.Group controlId="formStartDate" className="mb-3 form-group-custom">
                        <Form.Label>Startdatum*</Form.Label>
                        <DatePicker
                          selected={formState.start}
                          onChange={date => setFormState({ ...formState, start: date })}
                          dateFormat="dd.MM.yyyy"
                          placeholderText={todayFormatted}
                          isClearable
                          required
                          className="form-control"
                        />
                      </Form.Group>
                      
                      <Form.Group controlId="formEndDate" className="mb-3 form-group-custom">
                        <Form.Label>Enddatum (optional)</Form.Label>
                        <DatePicker
                          selected={formState.end}
                          onChange={date => setFormState({ ...formState, end: date })}
                          dateFormat="dd.MM.yyyy"
                          isClearable
                          placeholderText="Enddatum auswählen"
                          className="form-control"
                        />
                      </Form.Group>
                      
                      <Form.Group controlId="formGroupRef" className="mb-3 form-group-custom">
                        <Form.Label>Gruppe</Form.Label>
                        <Form.Control
                          as="select"
                          name="groupRef"
                          value={formState.groupRef}
                          onChange={handleFormChange}
                          className="form-control"
                          required  
                        >
                          <option value="">Bitte wählen</option>
                          {selectedKita.groups.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                          {/* Wochentage und Typ-Auswahl */}
                          <div className="weekday-tiles">
                            {Object.keys(selectedDays).map((day, index) => {
                              const dayCapitalized = day.charAt(0).toUpperCase() + day.slice(1);
                              const isSelected = selectedDays[day];
                              const typeKey = `type${dayCapitalized}`;
                              
                              return (
                                <div key={index} className="weekday-row mb-3">
                                  <div
                                    className={`weekday-tile d-block ${isSelected ? 'selected' : ''}`}
                                    style={{ width: '150px', maxWidth: '100%' }}
                                    onClick={() => toggleDaySelection(day, !isSelected)}
                                  >
                                    {dayCapitalized}
                                  </div>
                                  {isSelected && (
                                    <select
                                      className="weekday-dropdown"
                                      value={selectedDayTypes[typeKey]}
                                      onChange={(e) => handleDropdownChange(e, day)}
                                    >
                                      <option value="">Bitte auswählen</option>
                                      <option value="fullday">Ganzer Tag</option>
                                      <option value="halfday">Halber Tag</option>
                                    </select>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                      <Button variant="primary" type="submit">
                        Speichern
                      </Button>
                    </Form>
                  </Modal.Body>
                </Modal>
    </Card>
  );
};

export default ChildBetreuungszeiten;