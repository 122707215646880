import React, { useState, useContext, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import KitaContext from '../KitaContext';
import { auth } from '../firebase-config';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import '../styles/EmployeeList.css';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { selectedKita } = useContext(KitaContext);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [backendErrorPage, setBackendErrorPage] = useState('');
  const [backendError, setBackendError] = useState('');
  const [newEmployeeSuccess, setNewEmployeeSuccess] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [sendingActivationLink, setSendingActivationLink] = useState(false);
  const [activationLinkSent, setActivationLinkSent] = useState(false);
  const [employeeDocId, setEmployeeDocId] = useState('');
  const [employeeData, setEmployeeData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    address: '',
    zip: '',
    city: '',
    country: 'Schweiz',
    nationality: '',
    ahvnr: '',
    phone: '',
    sex: '',
    role: ''
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [employeeToUpdate, setEmployeeToUpdate] = useState(null);
  const [actionType, setActionType] = useState('');
  
  const fetchData = useCallback(async () => {
    try {
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
        const response = await fetch(`${baseUrl}/employees/kita/admin/${selectedKita.id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setEmployees(data);
        } else {
          console.error('Error fetching employee data');
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [selectedKita]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000); // Konvertiert den Timestamp in Millisekunden
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1, da Monate von 0-11 gezählt werden
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const openModal = () => {
    setEmployeeData({
      email: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      address: '',
      zip: '',
      city: '',
      country: 'Schweiz',
      nationality: '',
      ahvnr: '',
      phone: '',
      sex: '',
      role: ''
    });
    setSuccessModalMessage('');
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData(prevState => ({ ...prevState, [name]: value }));
  };

  const sendActivationRequestEmail = async () => {
    setSendingActivationLink(true);
    try {
      // Hier den spezifischen Link für die Aktivierung erstellen
      const baseUrl = window.location.hostname === 'localhost' 
        ? `http://localhost:3000/requestSignUpLink?email=${encodeURIComponent(employeeData.email)}&employeeId=${employeeDocId}&kitaId=${selectedKita.id}` 
        : `https://admin.smartkita.ch/requestSignUpLink?email=${encodeURIComponent(employeeData.email)}&employeeId=${employeeDocId}&kitaId=${selectedKita.id}`;
  
      // Hier die E-Mail-Nachricht erstellen
      const subject = `Sie wurden zur ${selectedKita.name} eingeladen`;
      const message = `Guten Tag ${employeeData.firstname} ${employeeData.lastname}\n\nSie werden zur ${selectedKita.name} eingeladen. Bitte klicken Sie auf folgenden Link, um die Aktivierung zu beantragen:\n${baseUrl}`;
  
      // Daten für die E-Mail-Anfrage an das Backend vorbereiten
      const emailData = {
        to: employeeData.email, // Empfänger-E-Mail-Adresse
        subject,
        message,
      };
  
      console.log('INITIALE AKTIVIERUNG emailData before JSON.stringify:', emailData); // Debug-Ausgabe
  
      // Senden Sie die E-Mail-Anfrage an Ihr Backend
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl1 = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

        const response = await fetch(`${baseUrl1}/send-activation-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(emailData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Erfolgsmeldung vom Server        
        setActivationLinkSent(true);
      } else {
        console.error('Fehler beim Senden der Aktivierungs-E-Mail.');
        setBackendError('Fehler beim Senden der Aktivierungs-E-Mail.');
      }
  
      console.log('Aktivierungs-E-Mail erfolgreich gesendet.');
    } catch (error) {
      console.error('Fehler beim Senden der Aktivierungs-E-Mail:', error);
      setBackendError('Fehler beim Senden der Aktivierungs-E-Mail.');
    }
    setSendingActivationLink(false);
  };

  const resendActivationEmail = async (employeeId, kitaId, email, firstname, lastname, kitaName) => {
    try {
      // Hier den spezifischen Link für die Aktivierung erstellen
      const baseUrl = window.location.hostname === 'localhost' 
        ? `http://localhost:3000/requestSignUpLink?email=${encodeURIComponent(email)}&employeeId=${employeeId}&kitaId=${kitaId}` 
        : `https://admin.smartkita.ch/requestSignUpLink?email=${encodeURIComponent(email)}&employeeId=${employeeId}&kitaId=${kitaId}`;
  
      // Hier die E-Mail-Nachricht erstellen
      const subject = `Sie wurden zur ${selectedKita.name} eingeladen`;
      const message = `Guten Tag ${firstname} ${lastname}\n\nSie wurden zur ${kitaName} eingeladen. Bitte klicken Sie auf folgenden Link, um die Aktivierung zu beantragen:\n${baseUrl}`;
  
      // Daten für die E-Mail-Anfrage an das Backend vorbereiten
      const emailData = {
        to: email, // Empfänger-E-Mail-Adresse
        subject,
        message,
      };
      console.log('REAKTIVIERUNG emailData before JSON.stringify:', emailData); // Debug-Ausgabe
  
      // Senden Sie die E-Mail-Anfrage an Ihr Backend
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl1 = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

        const response = await fetch(`${baseUrl1}/send-activation-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setSuccessMessage('Aktivierungslink erfolgreich gesendet.');
        setShowSuccessMessage(true);
      } else {
        const errorData = await response.json();
        setBackendError(errorData.message || 'Ein Fehler ist aufgetreten.');
      }
    } catch (error) {
      console.error('Fehler beim Senden des Aktivierungslinks:', error);
      setBackendError('Fehler beim Senden des Aktivierungslinks. Bitte versuchen Sie es erneut.');
    }
  };

  // Funktion zum Aktualisieren des Mitarbeiterstatus
  const updateEmployeeActivation = async (employeeId, kitaId, status) => {
    try {
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
            ? 'http://localhost:5000' 
            : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
        const response = await fetch(`${baseUrl}/update-activation/${employeeId}/${kitaId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`
            },
            body: JSON.stringify({ 
              status,
              changed_at: new Date().toISOString(), // aktuelles Datum und Uhrzeit
              changed_by: auth.currentUser.uid // aktuelle Benutzer-ID
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error updating activation:', errorData.message);
        } else {
            fetchData(); // Refresh the data after updating
        }
    } catch (error) {
        console.error('Error updating activation:', error);
    }
  };
  
  const closeModal = () => {
    setShowModal(false);
    setNewEmployeeSuccess(false);
    setActivationLinkSent(false);
    setBackendError('');
    setSuccessModalMessage('');
    fetchData();
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      
      // Generate a random 4-digit personal PIN as STRING
      const personalPin = Math.floor(1000 + Math.random() * 9000).toString();

      // Fügen Sie kitaRef dynamisch hinzu
      const finalEmployeeData = {
        ...employeeData,
        kitaRef: `kita/${selectedKita.id}`,
        created_at: new Date().toISOString(), // aktuelles Datum und Uhrzeit
        created_by: auth.currentUser.uid, // aktuelle Benutzer-ID //
        personalPin, // add the generated PIN
      };

      const response = await fetch(`${baseUrl}/register-employee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(finalEmployeeData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.existingUser) {
          setSuccessModalMessage(`Mitarbeiter ${finalEmployeeData.firstname} ${finalEmployeeData.lastname} erfolgreich hinzugefügt!`);
          setSuccessMessage(`Mitarbeiter ${finalEmployeeData.firstname} ${finalEmployeeData.lastname} erfolgreich hinzugefügt!`);
          setShowModal(false);
          setShowSuccessMessage(true);
          fetchData();
        } else {
          setEmployeeDocId(data.employeeDocId);
          setNewEmployeeSuccess(true);
        }
      } else {
        const errorData = await response.json();
        setBackendError(errorData.message || 'Ein Fehler ist aufgetreten.');
      }
    } catch (error) {
      console.error('Error:', error);
      setBackendError('Ein unerwarteter Fehler ist aufgetreten.');
    }
  };

  const handleToggleActivation = (employeeId, kitaId, currentStatus, uid) => {
    if (!uid) {
      setBackendErrorPage('Der Mitarbeiter hat den Aktivierungslink noch nicht getätigt.');
      return;
    }
    setEmployeeToUpdate({ employeeId, kitaId, newStatus: !currentStatus });
    setActionType('toggleActivation');
    setShowConfirmModal(true);
  };

  const resendActivationEmailPrompt = (employeeId, kitaId, email, firstname, lastname, kitaName) => {
    setEmployeeToUpdate({ employeeId, kitaId, email, firstname, lastname, kitaName });
    setActionType('resendActivationEmail');
    setShowConfirmModal(true);
  };

  const confirmAction = () => {
    if (actionType === 'toggleActivation' && employeeToUpdate) {
      updateEmployeeActivation(employeeToUpdate.employeeId, employeeToUpdate.kitaId, employeeToUpdate.newStatus);
    } else if (actionType === 'resendActivationEmail' && employeeToUpdate) {
      resendActivationEmail(employeeToUpdate.employeeId, employeeToUpdate.kitaId, employeeToUpdate.email, employeeToUpdate.firstname, employeeToUpdate.lastname, employeeToUpdate.kitaName);
    }
    setShowConfirmModal(false);
  };

  const filteredEmployees = employees.filter((employee) =>
    Object.values(employee)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <>
    <div className="container-md">
        {backendErrorPage && (
          <div className="alert alert-danger d-flex justify-content-between align-items-center">
            {backendErrorPage}
            <button 
              type="button" 
              className="btn-close" 
              aria-label="Close" 
              onClick={() => setBackendErrorPage('')}
            ></button>
          </div>
        )}
        {showSuccessMessage  && (
          <div className="alert alert-success d-flex justify-content-between align-items-center">
            {successMessage}
            <button 
              type="button" 
              className="btn-close" 
              aria-label="Close" 
              onClick={() => {
                setSuccessMessage('');
                setShowSuccessMessage(false);
              }}
            ></button>
          </div>
        )}
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="search">Suche:</label>
          <input
            type="text"
            id="search"
            className="form-control"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-end">
        <Button className="btn btn-primary" onClick={openModal}>Mitarbeiter hinzufügen</Button>
        </div>
      </div>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>Geburtsdatum</th>
              <th>Strasse</th>
              <th>PLZ</th>
              <th>Ort</th>
              <th>Land</th>
              <th>Nationalität</th>
              <th>AHV-Nr.</th>
              <th>Telefon</th>
              <th>E-Mail</th>
              <th>Geschlecht</th>
              <th>Role</th>
              <th>App PIN</th>
              <th>Status</th>
              <th>Aktivierungs-E-Mail</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee, index) => (
              <tr key={index}>
                  <td>{employee.firstname}</td>
                  <td>{employee.lastname}</td>
                  <td>{formatDate(employee.birthdate)}</td>
                  <td>{employee.address}</td>
                  <td>{employee.zip}</td>
                  <td>{employee.city}</td>
                  <td>{employee.country}</td>
                  <td>{employee.nationality}</td>
                  <td>{employee.ahvnr}</td>
                  <td>{employee.phone}</td>
                  <td>{employee.email}</td>
                  <td>
                    {employee.sex === 'm' && 'mänlich'}
                    {employee.sex === 'w' && 'weiblich'}
                  </td>
                  <td>
                    {employee.role === 'kitaEmployee' && 'Mitarbeiter/in'}
                    {employee.role === 'kitaGroupLead' && 'Gruppenleitung'}
                    {employee.role === 'kitaLead' && 'Kitaleitung'}
                    {employee.role === 'kitaOwner' && 'Geschäftsleitung'}
                  </td>
                  <td>{employee.personalPin}</td>

                  <td>
                    {employee.isActivatedEmployee ? (
                        <>
                            <span className="text-success">🟢</span>
                            <FontAwesomeIcon className="toggle-icon" icon={faToggleOn} onClick={() => handleToggleActivation(employee.id, selectedKita.id, employee.isActivatedEmployee, employee.uid)} />
                        </>
                    ) : (
                        <>
                            <span className="text-danger">🔴</span>
                            <FontAwesomeIcon className="toggle-icon" icon={faToggleOff} onClick={() => handleToggleActivation(employee.id, selectedKita.id, employee.isActivatedEmployee, employee.uid)} />
                        </>
                    )}
                </td>
                <td>
                  <button onClick={() => resendActivationEmailPrompt(employee.id, selectedKita.id, employee.email, employee.firstname, employee.lastname, selectedKita.name)}>📧</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>

    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Mitarbeiter hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {backendError && <div className="alert alert-danger">{backendError}</div>}
          {successModalMessage && <div className="alert alert-success text-center font-weight-bold">{successModalMessage}</div>}
          {newEmployeeSuccess ? (
            <div className="text-center">
              <p className="font-weight-bold">Mitarbeiter {employeeData.firstname} {employeeData.lastname} erfolgreich erstellt!</p>
              {sendingActivationLink ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">E-Mail wird versendet...</span>
                </Spinner>
              ) : activationLinkSent ? (
                <>
                  <p className="text-success">Aktivierungsemail erfolgreich gesendet!</p>
                  <Button onClick={closeModal}>Abschließen</Button>
                </>
              ) : (
                <Button onClick={sendActivationRequestEmail}>Aktivierungsemail senden</Button>
              )}
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="row mb-3">
                {/* E-Mail */}
                <Form.Group className="col-md-6">
                  <Form.Label>E-Mail Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={employeeData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Vorname */}
                <Form.Group className="col-md-6">
                  <Form.Label>Vorname</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={employeeData.firstname}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="row mb-3">
                {/* Nachname */}
                <Form.Group className="col-md-6">
                  <Form.Label>Nachname</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={employeeData.lastname}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Geburtsdatum */}
                <Form.Group className="col-md-6">
                  <Form.Label>Geburtsdatum</Form.Label>
                  <Form.Control
                    type="date"
                    name="birthdate"
                    value={employeeData.birthdate || ''}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="row mb-3">
                {/* Strasse */}
                <Form.Group className="col-12">
                  <Form.Label>Strasse</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={employeeData.address}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="row mb-3">
                {/* PLZ */}
                <Form.Group className="col-md-4">
                  <Form.Label>PLZ</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="4"
                    name="zip"
                    value={employeeData.zip}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Ort */}
                <Form.Group className="col-md-4">
                  <Form.Label>Ort</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={employeeData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Land */}
                <Form.Group className="col-md-4">
                  <Form.Label>Land</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={employeeData.country}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="row mb-3">
                {/* Nationalität */}
                <Form.Group className="col-md-6">
                  <Form.Label>Nationalität</Form.Label>
                  <Form.Control
                    type="text"
                    name="nationality"
                    value={employeeData.nationality}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* AHV-Nr. */}
                <Form.Group className="col-md-6">
                  <Form.Label>AHV-Nr.</Form.Label>
                  <Form.Control
                    type="text"
                    name="ahvnr"
                    value={employeeData.ahvnr}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="row mb-3">
                {/* Telefon */}
                <Form.Group className="col-md-4">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={employeeData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Geschlecht */}
                <Form.Group className="col-md-4">
                    <Form.Label>Geschlecht</Form.Label>
                    <Form.Select
                        name="sex"
                        value={employeeData.sex}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>Bitte auswählen</option>
                        <option value="w">weiblich</option>
                        <option value="m">männlich</option>
                    </Form.Select>
                </Form.Group>

                {/* Rolle */}
                <Form.Group className="col-md-4">
                    <Form.Label>Rolle</Form.Label>
                    <Form.Select
                        name="role"
                        value={employeeData.role}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>Bitte auswählen</option>
                        <option value="kitaEmployee">Mitarbeiter/in</option>
                        <option value="kitaGroupLead">Gruppenleitung</option>
                        <option value="kitaLead">Kitaleitung</option>
                        <option value="kitaOwner">Geschäftsleitung</option>
                    </Form.Select>
                </Form.Group>
              </div>

              <Button type="submit">Speichern</Button>
          </Form>
          )}
        </Modal.Body>
        </Modal>
        <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Bestätigung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {actionType === 'toggleActivation' && 
              'Sind Sie sicher, dass Sie den Status dieses Mitarbeiters ändern möchten?'}
            {actionType === 'resendActivationEmail' && 
              'Sind Sie sicher, dass Sie den Aktivierungslink erneut senden möchten?'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={confirmAction}>
              Bestätigen
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default EmployeeList;