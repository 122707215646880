import React from 'react';

const KitaContext = React.createContext({
  kitas: [],
  selectedKita: null,
  currentUserData: null,
  selectKita: () => {},
  triggerReloadKitas: () => {},
});

export default KitaContext;
