import React, { useState, useEffect, useContext, useRef } from 'react';
import KitaContext from '../KitaContext';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth } from '../firebase-config';
import '../styles/ChildPersonalInfo.css';

const ChildPersonalInfo = ({ childData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const { selectedKita } = useContext(KitaContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [profilePic, setProfilePic] = useState(childData.profilePic);
  const [isUploading, setIsUploading] = useState(false);
  const [stream, setStream] = useState(null);
  const [cameraMode, setCameraMode] = useState('open');
  const videoRef = useRef(null);

  // Initialisieren Sie previewSrc mit dem aktuellen Profilbild oder einem Platzhalterbild
  useEffect(() => {
    if (profilePic) {
      setPreviewSrc(profilePic);
    }
  }, [profilePic]);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  }, [stream, videoRef]);  

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Lesen Sie die Datei als Data-URL für die Vorschau
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
  
      // Speichern Sie das File-Objekt für den Upload
      setNewProfilePic(file);
    }
  };

  function generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16); // Klammern hinzugefügt
    });
    return uuid;
  }  

  const handleSaveChanges = async () => {
    try {
      if (newProfilePic) {
        setIsUploading(true);
        const uniqueRequestId = generateUUID(); // Funktion zur Generierung einer UUID
        // Generieren Sie den Zeitstempel
        const now = new Date();
        const timestamp = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;

        const formData = new FormData();
        formData.append('file', newProfilePic); // Geändert von 'picture' zu 'file'
        formData.append('useCase', 'childProfilePic');
        formData.append('docName', `${timestamp}_profilepic_${childData.firstname}_${childData.lastname}`);
        formData.append('type', 'image');
        formData.append('kitaRef', selectedKita.id);
        formData.append('childDocId', childData.id);
        formData.append('requestId', uniqueRequestId);
  
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost'
          ? 'http://localhost:5000'
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
        const response = await fetch(`${baseUrl}/uploadfile`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            // Entfernen Sie den Content-Type Header, da FormData ihn automatisch setzt
          },
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          setProfilePic(responseData.downloadUrl); // Aktualisieren des Profilbild-Links
          setShowEditModal(false); // Modal schließen
          setIsUploading(false);
        } else {
          console.error('Fehler beim Speichern des Profilbilds');
          const errorResponse = await response.json();
          setIsUploading(false);
          setErrorMessage(errorResponse.error || "Unbekannter Fehler beim Upload");
        }
      }
    } catch (error) {
      console.error('Fehler beim Speichern des Profilbilds', error);
      setIsUploading(false);
      setErrorMessage("Netzwerk- oder Serverfehler");
    }
  };


    const formatDate = (timestamp) => {
        const date = new Date(timestamp._seconds * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      };

      const handleCameraAction = async () => {
        if (cameraMode === 'open' || cameraMode === 'reopen') {
          try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(mediaStream);
            setCameraMode('takePhoto');
            setPreviewSrc(null); // Löscht die aktuelle Bildvorschau
            //console.log(videoElement);
          } catch (error) {
            console.error('Fehler beim Zugriff auf die Kamera:', error);
            setErrorMessage('Fehler beim Zugriff auf die Kamera');
          }
        } else if (cameraMode === 'takePhoto') {
          if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      
            canvas.toBlob((blob) => {
              if (blob) {
                setPreviewSrc(URL.createObjectURL(blob));
                setNewProfilePic(new File([blob], "webcam_photo.jpeg", { type: "image/jpeg" }));
                setCameraMode('reopen');
              } else {
                console.error('Fehler beim Erstellen des Blobs');
              }
            });
            
            if (stream) {
              stream.getTracks().forEach(track => track.stop());
            }
            setStream(null); // Schließt den Video-Stream
          }
      }
      };
      

  return (
    <>
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <div>
          <Button variant="secondary">
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="mr-2"
                    // onClick={() => editChildInformation} // Fügen Sie eine Funktion zum Bearbeiten hinzu
                  />
          </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
      <div className="profile-pic-container" style={{ position: 'relative' }}>
        <Image
          src={profilePic || 'placeholder-image-url'}
          roundedCircle
          className="profile-pic mb-3"
        />
        <div className="edit-icon-overlay" onClick={handleEditClick}>
          <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
        </div>
      </div>
      <Card.Title>{`${childData.firstname} ${childData.lastname}`}</Card.Title>
      <Table striped borderless responsive>
        <tbody>
          <tr>
            <td><strong>Geburtsdatum:</strong></td>
            <td>{formatDate(childData.birthdate)}</td>
          </tr>
          <tr>
            <td><strong>Adresse:</strong></td>
            <td>{childData.address}, {childData.zip} {childData.city}</td>
          </tr>
          <tr>
            <td><strong>Bio. Geschlecht:</strong></td>
            <td>{childData.sex === 'm' ? 'Männlich' : 'Weiblich'}</td>
          </tr>
          <tr>
            <td><strong>Aktives Kind:</strong></td>
            <td>{childData.isActiveChild ? 'Ja' : 'Nein'}</td>
          </tr>
          </tbody>
          </Table>
        </Card.Body>
        </Card>
        <Modal show={showEditModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Profilbild bearbeiten</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {isUploading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
            {/* Fehlermeldung anzeigen, falls vorhanden */}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              {/* Bildvorschau */}
              {!stream ? (
                <>
                <Image
                  src={previewSrc || profilePic || 'placeholder-image-url'}
                  alt="Bildvorschau"
                  className="img-fluid mb-3"
                />
              {/* Eingabefeld für Dateien */}
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="fileUpload"
                accept="image/*"
              />
              </>
              ) : (
                <video ref={videoRef} autoPlay playsInline style={{ width: '100%', display: 'block' }}></video>
              )}
                {/* Button, um das Eingabefeld für Dateien zu aktivieren */}
                <Button
                variant="primary"
                onClick={() => document.getElementById('fileUpload').click()}
              >
                Neues Bild
              </Button>
              <Button variant="secondary" onClick={handleCameraAction} className="desktop-camera-button">
                {cameraMode === 'open' ? 'Kamera öffnen DESKTOP' : cameraMode === 'takePhoto' ? 'Foto aufnehmen DESKTOP' : 'NEU Desktop'}
              </Button>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Speichern
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default ChildPersonalInfo;