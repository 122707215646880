import React, { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase-config';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { sendSignInLinkToEmail } from 'firebase/auth';
import '../styles/RequestSignUpLink.css';

const RequestSignUpLink = () => {
  const [backendError, setBackendError] = useState('');
  const [sendingActivationLink, setSendingActivationLink] = useState(false);
  const [activationLinkSent, setActivationLinkSent] = useState(false);
  const [isValidRequestor, setIsValidRequestor] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [redirectTo, setRedirectTo] = useState('');
  const [loading, setLoading] = useState(true); 

  const urlParams = new URLSearchParams(window.location.search);
  const emailFromParams = urlParams.get('email');
  const employeeDocIdFromParams = urlParams.get('employeeId');
  const kitaIdFromParams = urlParams.get('kitaId');


  const checkIfUserIsLegit = useCallback(async () => {
    setLoading(true); 
    try {
      const baseUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      const response = await fetch(`${baseUrl}/check-if-user-is-legit/${employeeDocIdFromParams}/${emailFromParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setIsValidRequestor(true);
      } else {
        const errorData = await response.json();
        setModalMessage(errorData.message);
        setRedirectTo(errorData.redirectTo);
        console.log(errorData);
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Benutzers:', error);
      setModalMessage('Fehler beim Überprüfen des Benutzers. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.');
    } finally {
      // Nach der Überprüfung wird der Ladezustand auf false gesetzt, aber erst nach 2 Sekunden
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [emailFromParams, employeeDocIdFromParams]);
  
  useEffect(() => {
    if (emailFromParams && employeeDocIdFromParams) {
      checkIfUserIsLegit();
    }
  }, [checkIfUserIsLegit, emailFromParams, employeeDocIdFromParams]);

  const sendActivationLink = async () => {
    if (!isValidRequestor) {
      setModalMessage("Sie haben das Limit von 1 Aktivierungslink pro Stunde erreicht. Bitte schauen Sie in Ihrem Postfach (evtl. auch SPAM) nach oder versuchen Sie es später nochmals.");
      return;
    }

    setSendingActivationLink(true);

    try {
      const appUrl = window.location.hostname === 'localhost'
        ? `http://localhost:3000/finishSignUpEmployee?email=${encodeURIComponent(emailFromParams)}&employeeId=${employeeDocIdFromParams}&kitaId=${kitaIdFromParams}`
        : `https://admin.smartkita.ch/finishSignUpEmployee?email=${encodeURIComponent(emailFromParams)}&employeeId=${employeeDocIdFromParams}&kitaId=${kitaIdFromParams}`;

      const actionCodeSettings = {
        url: appUrl,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, emailFromParams, actionCodeSettings);

      const baseUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/save-activation-link/${employeeDocIdFromParams}/${kitaIdFromParams}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ link: appUrl, sent_by: emailFromParams }),
      });

      if (response.ok) {
        setActivationLinkSent(true);
      } else {
        const errorData = await response.json();
        setModalMessage(errorData.message || 'Ein Fehler ist aufgetreten.');
        setBackendError(errorData.message || 'Ein Fehler ist aufgetreten.');
      }
    } catch (error) {
      console.error('Fehler beim Senden des Aktivierungslinks:', error);
      setModalMessage('Fehler beim Senden des Aktivierungslinks. Bitte versuchen Sie es erneut.');
      setBackendError('Fehler beim Senden des Aktivierungslinks. Bitte versuchen Sie es erneut.');
    } finally {
      setSendingActivationLink(false);
    }
  };

  return (
    <div className="centered-container">
      <div className="signup-box">
        {activationLinkSent ? (
          <div className="text-center">
            <h2>Aktivierungslink wurde gesendet</h2>
            <p>Ihr Aktivierungslink wurde erfolgreich an {emailFromParams} gesendet. Bitte schauen Sie in Ihrem Postfach (evtl. auch SPAM) nach und aktivieren Sie Ihren Account innerhalb von 60 Minuten. Danach wird der Aktivierungslink ungültig.</p>
          </div>
        ) : (
          <>
            {loading ? ( // Zeige Spinner, wenn das Laden im Gange ist
              <div className="text-center">
                <Spinner animation="border" variant="primary" role="status">
                  <span className="visually-hidden">Laden...</span>
                </Spinner>
                <p>Überprüfung läuft...</p>
              </div>
            ) : (
              <>
                {modalMessage ? (
                  <div className="text-center">
                    <p>{modalMessage}</p>
                    {redirectTo && (
                      <Button variant="primary" onClick={() => window.location.href = redirectTo}>
                        Zur Startseite
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <h2>Aktivierungslink anfordern</h2>
                    <p>Ihre E-Mail-Adresse: {emailFromParams}</p>
                    {backendError && <div className="alert alert-danger">{backendError}</div>}
                    {sendingActivationLink ? (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden">Laden...</span>
                        </Spinner>
                        <p>Aktivierungslink wird gesendet...</p>
                      </div>
                    ) : (
                      <Button variant="primary" onClick={sendActivationLink}>Aktivierung beantragen</Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RequestSignUpLink;
