import React, { useEffect, useState } from 'react';
import ForgotPassword from './ForgotPassword';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase-config';
import './styles/LoginForm.css';

const LoginForm = ({ onLogin, loginErrorCode }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false); 
  const [resetError, setResetError] = useState(null);

  // Diese useEffect wird ausgelöst, wenn sich der loginErrorCode ändert
  useEffect(() => {
    if (loginErrorCode) {
      // Wenn ein loginErrorCode vorhanden ist, setze die Fehlermeldung mit getErrorMessage
      setLoginError(getErrorMessage(loginErrorCode));
    } else {
      // Andernfalls, wenn kein loginErrorCode vorhanden ist, setze die Fehlermeldung auf null
      setLoginError(null);
    }
  }, [loginErrorCode]);

  // Logindata is sent to onlogin which comes from APP.js. Login is performed there and if an error happens, the error.code will be sent back to LoginForm and handled by the useEffect
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await onLogin({ username, password });
      setLoginError(null);

    } catch (error) {
      console.error('Login error:', error.code, error.message);
    }
  };
  
  // Eine Funktion, um die Fehlermeldung basierend auf dem Fehlercode zu erhalten
  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'Fehlgeschlagen. Es wurde kein Benutzer für Ihre E-Mail Adresse gefunden.';
        case 'auth/missing-password':
          return 'Fehlgeschlagen. Bitte überprüfen Sie Ihr Passwort.';
        case 'auth/missing-email':
          return 'Fehlgeschlagen. Bitte überprüfen Sie Ihr E-Mail Adresse.';
          case 'auth/invalid-email':
            return 'Fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.';
            case 'auth/wrong-password':
              return 'Fehlgeschlagen. Bitte überprüfen Sie Ihr Passwort.';
      default:
        return 'Fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.';
    }
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  const handleBackToLogin = () => {
    setShowForgotPassword(false);
  };

  //the password reset is sent from the ForgotPassword.js and handled here. Also Error Handling is handled here
  const handleResetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetError(null);
    } catch (error) {
      console.error('Password reset error:', error.code, error.message);
      setResetError(getErrorMessage(error.code));
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card" style={{ width: '350px' }}>
        <div className="card-header text-center">
          <img src="/smartkita-192x192.png" alt="Logo" height="80" />
        </div>
        <div className="card-body text-center">
          {showForgotPassword ? (
            <>
            {resetError && (
              <div className="alert alert-danger" role="alert">
                {resetError}
              </div>
            )}
            <ForgotPassword onBackToLogin={handleBackToLogin} onResetPassword={handleResetPassword} />
            </>
          ) : (
            <>
              {loginError && (
                <div className="alert alert-danger" role="alert">
                  {loginError}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control text-center"
                    placeholder="E-Mail Adresse"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control text-center"
                    placeholder="Passwort"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Login
                </button>
                <p className="mt-3 text-center">
                  <span
                    onClick={handleForgotPasswordClick}
                    className="link"
                    style={{ cursor: 'pointer' }}>
                    Passwort vergessen?
                  </span>
                </p>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
