import React, { useContext } from 'react';
import { auth } from './firebase-config';
import KitaContext from './KitaContext';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import './styles/Home.css';

const Home = () => {
  const { selectedKita, currentUserData } = useContext(KitaContext);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  if (!selectedKita || !currentUserData) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="sr-only">Daten werden geladen...</span>
        </Spinner>
        <div className="mt-3">Daten werden geladen...</div>
      </div>
    );
  }

  const profilePic = currentUserData.profilePic || `${process.env.PUBLIC_URL}/employeeProfilePic.png`;

  return (
    <div className="container mt-5">
      <Card className="card-custom">
        <Card.Body className="card-body-custom-center">
          <Card.Title>Profil</Card.Title>
          <Card.Img variant="top" src={profilePic} className="profile-pic" />
          <div className="card-body-custom">
            <div className="info-row">
              <div className="info-label"><strong>Name:</strong></div>
              <div className="info-value">{`${currentUserData.firstname} ${currentUserData.lastname}`}</div>
            </div>
            <div className="info-row">
              <div className="info-label"><strong>Geburtstag:</strong></div>
              <div className="info-value">{formatDate(currentUserData.birthdate)}</div>
            </div>
            <div className="info-row">
              <div className="info-label"><strong>Tel:</strong></div>
              <div className="info-value">{currentUserData.phone}</div>
            </div>
            <div className="info-row">
              <div className="info-label"><strong>E-Mail:</strong></div>
              <div className="info-value">{auth.currentUser.email}</div>
            </div>
            <div className="info-row">
              <div className="info-label"><strong>Adresse:</strong></div>
              <div className="info-value">{`${currentUserData.address}, ${currentUserData.zip} ${currentUserData.city}`}</div>
            </div>
            <div className="info-row">
              <div className="info-label"><strong>App PIN:</strong></div>
              <div className="info-value"><strong>{currentUserData.personalPin}</strong></div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="card-custom">
        <Card.Body className="card-body-custom">
          <Card.Title>Kita Informationen</Card.Title>
          <div className="info-row">
            <div className="info-label"><strong>Name:</strong></div>
            <div className="info-value">{selectedKita.name}</div>
          </div>
          <div className="info-row">
            <div className="info-label"><strong>Adresse:</strong></div>
            <div className="info-value">{selectedKita.address}, {selectedKita.zip} {selectedKita.city}</div>
          </div>
          <div className="info-row">
            <div className="info-label"><strong>Email:</strong></div>
            <div className="info-value">{selectedKita.email}</div>
          </div>
          <div className="info-row">
            <div className="info-label"><strong>Website:</strong></div>
            <div className="info-value">
              <a href={selectedKita.website} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'none' }}>
                {selectedKita.website}
              </a>
            </div> 
          </div>
          <div className="info-row">
            <div className="info-label"><strong>Telefon:</strong></div>
            <div className="info-value">{selectedKita.phone}</div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Home;
