// ChildInfoCard.js
import React from 'react';
import Card from 'react-bootstrap/Card';

const ChildInfoCard = ({ title, children }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {children}
      </Card.Body>
    </Card>
  );
};

export default ChildInfoCard;
