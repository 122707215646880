import React, { useEffect, useState, useCallback  } from 'react';
import { useParams, Link } from 'react-router-dom';
import { auth } from '../firebase-config';
import Spinner from 'react-bootstrap/Spinner';
import ChildInfoCard from './ChildInfoCard';
import ChildPersonalInfo from './ChildPersonalInfo';
import ChildBetreuungszeiten from './ChildCareInfo';
import ChildFirstContact from './ChildFirstContact';

const ChildDetail = ({ onBack }) => {
  const { childId } = useParams(); // Die ID des Kindes aus der URL holen
  const [childData, setChildData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

 // Extrahiere die fetchChildData-Funktion in eine separate Funktion
 const fetchChildData = useCallback(async () => {
  try {
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      const response = await fetch(`${baseUrl}/child/${childId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      if (response.ok) {
        const childData = await response.json();
        setChildData(childData);
        console.log(childData);
        setIsLoading(false);
      } else {
        console.error('Error fetching child data');
      }
    }
  } catch (error) {
    console.error(error);
    setIsLoading(false);
  }
}, [childId]);

useEffect(() => {
  // Rufe die fetchChildData-Funktion auf
  fetchChildData();
}, [fetchChildData]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!childData) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="container">
      <Link to="/children" className="btn btn-primary">
        Zurück zur Kinderliste
      </Link>

      <div className="row">
        <div className="col-md-6">
          <ChildInfoCard title="Persönliche Informationen">
            <ChildPersonalInfo childData={childData} />
          </ChildInfoCard>
        </div>
        <div className="col-md-6">
          <ChildInfoCard title="Betreuungszeiten">
          <ChildBetreuungszeiten childId={childId}/>
          </ChildInfoCard>
        </div>
        <div className="col-md-6">
          <ChildInfoCard title="Erster Kontakt">
            <ChildFirstContact childId={childId} />
          </ChildInfoCard>
        </div>
        {/* Weitere Kacheln hinzufügen */}
      </div>
    </div>
  );
};

export default ChildDetail;
