import { useEffect, useState, useContext, useCallback } from 'react';
import { isSignInWithEmailLink, signInWithEmailLink, getAuth, updatePassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import KitaContext from '../KitaContext';
import { auth as firebaseAuth } from '../firebase-config';
import '../styles/FinishSignUpEmployee.css';

const FinishSignUpEmployee = () => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isEmplAndKitaDataLoaded, setIsEmplAndKitaDataLoaded] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [currentUserEmail, setCurrentUserEmail] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromLink = urlParams.get('email');
    const navigate = useNavigate();
    const { triggerReloadKitas } = useContext(KitaContext);
    const [employeeData, setEmployeeData] = useState(null);
    const [kitaData, setKitaData] = useState(null);

    const updateUserActivation = useCallback(async () => {
        const employeeId = urlParams.get('employeeId');
        const kitaId = urlParams.get('kitaId');
    
        try {
            if (employeeId && kitaId) {
                const idToken = await firebaseAuth.currentUser.getIdToken();
                const baseUrl = window.location.hostname === 'localhost' 
                    ? 'http://localhost:5000' 
                    : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
    
                    const response = await fetch(`${baseUrl}/update-activation/${employeeId}/${kitaId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${idToken}`
                        },
                        body: JSON.stringify({ status: true, changed_by: firebaseAuth.currentUser.uid, changed_at: new Date().toISOString() }) // Always set to true for this component
                    });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error updating activation:', errorData.message);
                }
            }
        } catch (error) {
            console.error('Error updating activation:', error);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    
    const fetchEmployeeAndKitaData = useCallback(async () => {
        const employeeId = urlParams.get('employeeId');
        const kitaId = urlParams.get('kitaId');

        try {
            if (employeeId && kitaId) {
                const idToken = await firebaseAuth.currentUser.getIdToken();
                const baseUrl = window.location.hostname === 'localhost' 
                    ? 'http://localhost:5000' 
                    : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

                    // Mitarbeiterdaten laden
                    const employeeResponse = await fetch(`${baseUrl}/employee/${employeeId}/${kitaId}`, {
                        headers: {
                            Authorization: `Bearer ${idToken}`
                        }
                    });

                    if (employeeResponse.ok) {
                        const employeeData = await employeeResponse.json();
                        setEmployeeData(employeeData);
                        setIsEmplAndKitaDataLoaded(true);
                    } else {
                        console.error('Error fetching employee data');
                        setIsEmplAndKitaDataLoaded(false);
                    }

                    // KITA-Daten laden
                    const kitaResponse = await fetch(`${baseUrl}/kita/${kitaId}`, {
                        headers: {
                            Authorization: `Bearer ${idToken}`
                        }
                    });

                    if (kitaResponse.ok) {
                        const kitaData = await kitaResponse.json();
                        setKitaData(kitaData);
                        setIsEmplAndKitaDataLoaded(true);
                    } else {
                        console.error('Error fetching KITA data');
                        setIsEmplAndKitaDataLoaded(false);
                    }
                }
        } catch (error) {
            console.error(error);
            setIsEmplAndKitaDataLoaded(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { 
        const auth = getAuth();
        if (isConfirmed) return; 
        if (isSignInWithEmailLink(auth, window.location.href)) {
            signInWithEmailLink(auth, emailFromLink, window.location.href)
                .then(async (result) => {
                    const uid = result.user.uid;
                    const email = result.user.email;
                    setCurrentUserEmail(email);
                    console.log(uid);
        
                    // Hier den Backend-Endpunkt aufrufen, um die UID zu aktualisieren
                    if (auth.currentUser) {
                        const idToken = await firebaseAuth.currentUser.getIdToken();
                        const baseUrl = window.location.hostname === 'localhost' 
                            ? 'http://localhost:5000' 
                            : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
                        fetch(`${baseUrl}/update-employee-uid`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${idToken}`
                            },
                            body: JSON.stringify({ email: email, uid: uid }),
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.message) {
                                console.log(data.message);
                            }
                        })
                        .catch(error => {
                            console.error('Error updating UID:', error);
                        });
                    }
                    
                    setIsConfirmed(true);
    
                    await updateUserActivation();
                    fetchEmployeeAndKitaData();
                })
                .catch((error) => {
                    console.error('Error during sign in with email link:', error);
                });
        }
    }, [isConfirmed, emailFromLink, updateUserActivation, fetchEmployeeAndKitaData]);
    
    
    const handlePasswordSet = async () => {
        if (password !== confirmPassword) {
            setPasswordError("Passwörter stimmen nicht überein!");
            return;
        }

        const auth = getAuth();
        if (auth.currentUser) {
            try {
                await updatePassword(auth.currentUser, password);
                console.log("Passwort erfolgreich gesetzt und Benutzer angemeldet!");
                triggerReloadKitas();
                navigate('/');
            } catch (error) {
                console.error("Fehler beim Setzen des Passworts:", error);
            }
        }
    };

    if (isConfirmed && currentUserEmail && isEmplAndKitaDataLoaded) {
        return (
            <div style={centeredStyle}>
                <div className="signup-container bg-white shadow rounded p-4 d-flex flex-column align-items-center">
                    {/* KITA-Logo */}
                    {kitaData && kitaData.logo && (
                        <img src={kitaData.logo} alt="KITA Logo" style={{ width: '250px', marginBottom: '20px' }} />
                    )}
    
                    {/* Begrüßungstext */}
                    <p className="text-center">
                        Guten Tag {employeeData && employeeData.sex === 'w' ? 'Frau' : 'Herr'} <b>{employeeData && `${employeeData.firstname} ${employeeData.lastname}`}</b><br />
                        Willkommen beim Portal der <b>{kitaData && kitaData.name}</b>.<br />
                        Ihr Account wurde erfolgreich erstellt.<br />
                        Sie haben es bald geschafft.<br />
                        Nur noch Passwort setzen und los geht's.
                    </p>
    
                    {/* Passwortfelder */}
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Passwort setzen" 
                        className="form-control mb-2 password-input"
                    />
                    
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        placeholder="Passwort bestätigen" 
                        className="form-control mb-2 password-input"
                    />
                    
                    {passwordError && <div className="alert alert-danger w-50 text-center">{passwordError}</div>}
                    <button onClick={handlePasswordSet} className="btn btn-primary mt-2 signup-button">Passwort setzen und fortfahren</button>
                </div>
            </div>
        );
    }
    
    return (
        <div style={centeredStyle}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Laden...</span>
            </div>
        </div>
    );
    
};

const centeredStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
};

export default FinishSignUpEmployee;
