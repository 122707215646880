import React, { useState, useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProfileIcon, LeaderboardIcon } from '../icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { auth } from './../firebase-config';
import KitaContext from './../KitaContext';

const MyNavbar = ({ onLogout }) => {
  const [expanded, setExpanded] = useState(false);
  const { kitas, selectedKita, selectKita, currentUserData } = useContext(KitaContext);

  return (
    <Navbar className="custom-nav" expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Navbar.Brand as={Link} to="/">
        <img src={selectedKita?.logo} alt={`Logo von ${selectedKita?.name}`} style={{ maxWidth: '100%', maxHeight: '100px' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {auth.currentUser ? (
            <>
              {!currentUserData ? (
                <Nav.Link className="custom-nav-link">Loading...</Nav.Link>
              ) : (
                <>
                  <Nav.Link as={Link} to="/" onClick={() => { setExpanded(false); }} className="custom-nav-link">
                    Home
                  </Nav.Link>
                  {(currentUserData.role === 'kitaOwner' || currentUserData.role === 'kitaLead') && (
                    <>
                      <Nav.Link as={Link} to="/attendancelist" onClick={() => setExpanded(false)} className="custom-nav-link">
                        Anwesenheitsliste
                        <ProfileIcon className="custom-icon" />
                      </Nav.Link>
                      <Nav.Link as={Link} to="/children" onClick={() => setExpanded(false)} className="custom-nav-link">
                        Kinder
                        <ProfileIcon className="custom-icon" />
                      </Nav.Link>
                      <Nav.Link as={Link} to="/employees" onClick={() => setExpanded(false)} className="custom-nav-link">
                        Mitarbeiter
                        <ProfileIcon className="custom-icon" />
                      </Nav.Link>
                      <Nav.Link as={Link} to="/register-user" onClick={() => setExpanded(false)} className="custom-nav-link">
                        Register User
                        <LeaderboardIcon className="custom-icon" />
                      </Nav.Link>
                    </>
                  )}
                  {kitas.length > 1 && (
                    <Nav.Link as={Link} to="/" onClick={() => selectKita(null)} className="custom-nav-link">
                      Kita wechseln
                      <FontAwesomeIcon icon={faExchangeAlt} className="custom-icon" />
                    </Nav.Link>
                  )}
                  <Nav.Link as={Link} to="/" onClick={onLogout} className="custom-nav-link">
                    Logout
                    <FontAwesomeIcon icon={faSignOutAlt} className="custom-icon" />
                  </Nav.Link>
                </>
              )}
            </>
          ) : (
            <>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
