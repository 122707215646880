import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginForm from './LoginForm';
import KitaSelectionForm from './KitaSelectionForm';
import MyNavbar from './shared/navbar';
import Home from './home';
import Footer from './shared/footer';
import RegisterUser from './admin/RegisterUser';
import ChildrenList from './child/ChildrenList';
import ChildDetail from './child/ChildDetail';
import EmployeesList from './employee/EmployeesList';
import KitaContext from './KitaContext';
import FinishSignUpEmployee from './employee/FinishSignUpEmployee';
import RequestSignUpLink from './employee/RequestSignUpLink';
import AttendanceList from './attendanceChild/AttendanceList';
import { auth } from './firebase-config';
import { 
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword
    } from "firebase/auth";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [selectedKita, setSelectedKita] = useState(null);
    const [isLoadingKitas, setIsLoadingKitas] = useState(false);
    const [kitas, setKitas] = useState([]);
    const [shouldReloadKitas, setShouldReloadKitas] = useState(false);
    const [loginErrorCode, setLoginErrorCode] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsAuthenticated(true);
        loadKitas(user); // Load kitas when user is authenticated
      } else {
        setIsAuthenticated(false);
        setSelectedKita(null); // Reset selected kita on logout
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (shouldReloadKitas && isAuthenticated) {
      loadKitas(auth.currentUser);
      setShouldReloadKitas(false); // Setzen Sie es zurück, damit es nicht erneut ausgelöst wird
    }
  }, [shouldReloadKitas, isAuthenticated]);

  const loadKitas = async (user) => {
    setIsLoadingKitas(true);
    try {
      const idToken = await user.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/employeeKitas/${user.uid}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
  
      if (!response.ok) {
        console.error('Server response:', response.status, await response.text());
        throw new Error('Failed to fetch Kitas from server');
      }
  
      const data = await response.json();
      setKitas(data);
  
      if (data.length === 1) {
        setSelectedKita(data[0]);
      }
    } catch (error) {
      console.error('Error in loadKitas:', error);
      // Hier können Sie weitere Maßnahmen ergreifen, z.B. den Benutzer über den Fehler informieren.
    } finally {
      setIsLoadingKitas(false);
    }
  }; 

  // Load data for specific Employee or User and specific Kita
  const loadCurrentUserData = async (uid) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

          // Mitarbeiterdaten laden
          const employeeResponse = await fetch(`${baseUrl}/currentUser/${uid}/${selectedKita.id}`, {
              headers: {
                  Authorization: `Bearer ${idToken}`
              }
          });

          if (employeeResponse.ok) {
              const employeeData = await employeeResponse.json();
              setCurrentUserData(employeeData);
              console.log(employeeData);
          } else {
              console.error('Error fetching employee data');
          }

    } catch (error) {
      console.error('Error in loadKitas:', error);
      // Hier können Sie weitere Maßnahmen ergreifen, z.B. den Benutzer über den Fehler informieren.
    } finally {

    }
  }; 

  const handleLogin = async (userdata) => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        userdata.username,
        userdata.password
      );
      loadKitas(user.user);
    } catch (error) {
      console.log(error.message);
      setLoginErrorCode(error.code);
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
  };

  const handleRegister = async (userdata) => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        userdata.email,
        userdata.password
      );
      console.log(user);
  
    } catch (error) {
      console.log(error.message);
    }
  };

  // reload Logged In User Data when the kita has been loaded/selected
  useEffect(() => {
    if (selectedKita && auth.currentUser) {
        loadCurrentUserData(auth.currentUser.uid);
    }
    if (selectedKita === null) {
      setCurrentUserData(null);
      console.log("currentuser wurde auf null gesetzt");
      return;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedKita]);


  const handleSelectKita = (kita) => {
    setSelectedKita(kita);
    console.log(kita);
  };

  return (
    <KitaContext.Provider value={{ 
      kitas, 
      selectedKita,
      currentUserData,
      selectKita: handleSelectKita, 
      triggerReloadKitas: () => setShouldReloadKitas(true) 
    }}>
      <div>
        <Routes>
          <Route path="/requestSignUpLink" element={<RequestSignUpLink />} />
          <Route path="/finishSignUpEmployee" element={<FinishSignUpEmployee />} />
          <Route path="*" element={
            !isAuthenticated ? (
              <LoginForm onLogin={handleLogin} loginErrorCode={loginErrorCode} />
            ) : isLoadingKitas ? (
              <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div>Ihre Kita wird geladen...</div>
                </div>
              </div>
            ) : !selectedKita ? (
              <KitaSelectionForm kitas={kitas} onSelectKita={handleSelectKita} />
            ) : (
              <>
                <MyNavbar onLogout={handleLogout} />
                <Routes>
                  <Route path="/" element={<Home />} />    
                  <Route path="/children" element={<ChildrenList />} />
                  <Route path="/children/:childId" element={<ChildDetail />} />
                  <Route path="/attendancelist" element={<AttendanceList />} />
                  <Route path="/employees" element={<EmployeesList />} />        
                  <Route path="/register-user" element={<RegisterUser onRegister={handleRegister} />} />
                  {/* Add other routes as needed */}
                </Routes>
                <Footer />
              </>
            )
          } />
        </Routes>
      </div>
    </KitaContext.Provider>
  );
  
};

export default App;
