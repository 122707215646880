const KitaSelectionForm = ({ kitas, onSelectKita }) => {
  const handleKitaSelection = (kitaId) => {
    const selected = kitas.find(kita => kita.id === kitaId);
    onSelectKita(selected);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="w-100">
        <h2 className="text-center mb-4">Wählen Sie eine Kita</h2>
        <div className="row">
          {kitas.length > 0 ? (
            kitas.map(kita => (
              <div 
                key={kita.id} 
                className="col-12 col-md-4 mb-3"
                onClick={() => handleKitaSelection(kita.id)}
                style={{
                  border: '1px solid #ccc', 
                  padding: '10px', 
                  borderRadius: '5px', 
                  display: 'flex', 
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center', 
                  cursor: 'pointer', 
                  transition: 'background-color 0.3s',
                  height: '220px' // Adjust this value as needed
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f7f7f7'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
              >
                <div style={{ 
                  width: '150px', 
                  height: '150px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center' 
                }}>
                  <img 
                    src={kita.logo} 
                    alt={`Logo von ${kita.name}`} 
                    style={{ 
                      maxWidth: '100%', 
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }} 
                  />
                </div>
                <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{kita.name}</p>
              </div>
            ))
          ) : (
            <div className="col-12">
              <p>Es wurden keine Kitas gefunden.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KitaSelectionForm;
