import React, { useState, useEffect, useContext } from 'react';
import KitaContext from '../KitaContext';
import { auth } from '../firebase-config';
import CalculateAttendance from './CalculateAttendance';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../styles/AttendanceList.css';

const AttendanceList = () => {
  const { selectedKita } = useContext(KitaContext);
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hasAttendanceEntry, setHasAttendanceEntry] = useState(null);
  const [dailyCloseBy, setDailyCloseBy] = useState('');
  const [dailyCloseAt, setDailyCloseAt] = useState('');

  useEffect(() => {
    const checkAttendance = async () => {
      setIsLoading(true);
      try {
        if (auth.currentUser) {
          const idToken = await auth.currentUser.getIdToken();
          const baseUrl = window.location.hostname === 'localhost'
            ? 'http://localhost:5000'
            : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
            const response = await fetch(`${baseUrl}/check-attendance/${selectedKita.id}?timestamp=${selectedDate}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            });
  
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data && data.found) {
              setHasAttendanceEntry(true);
              setChildren(data.children);
              setDailyCloseBy(data.dailyCloseBy);
              setDailyCloseAt(data.dailyCloseAt);
            } else {
              setHasAttendanceEntry(false);
              // hier muss später die Anwesenheitsliste calculiert werden
              setChildren([]); // Setzt die Kinderliste zurück, wenn kein Eintrag gefunden wird
            }
          } else {
            console.error('Fehler beim Abrufen der Anwesenheitsdaten');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    checkAttendance();
  }, [selectedKita, selectedDate]);

  const handleDateChange = (dateValue) => {
    setHasAttendanceEntry(false);
    if (dateValue instanceof Date && !isNaN(dateValue)) {
      setSelectedDate(dateValue);
      setIsLoading(true);
    } else {
      console.error("The provided value is not a valid Date:", dateValue);
    }
  };

  const navigateDay = (days) => {
    setHasAttendanceEntry(false);
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const getGroupNameById = (groupId) => {
    const group = selectedKita.groups.find((group) => group.id === groupId);
    return group ? group.name : 'N/A';
  };

  return (
    <div className="attendance-list container-md">
      <h1>Anwesenheitsliste</h1>
      <Card.Title>
      <span className="mb-3">Ausgewähltes Datum: {selectedDate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
        {hasAttendanceEntry && (
          <span className="closing-info">
            Der {selectedDate.toLocaleDateString('de-DE')} wurde am {new Date(dailyCloseAt._seconds * 1000).toLocaleDateString('de-DE')} von {dailyCloseBy} abgeschlossen.
          </span>
        )}
      </Card.Title>
      <div className="date-picker-container mb-3 mt-3">
        <div className="date-picker-button">
          <Button variant="outline-secondary" onClick={() => navigateDay(-1)}>
            <span className="arrow-icon">←</span>
          </Button>
        </div>
        <div className="date-picker-input">
          <Form.Group>
            <Form.Control
              type="date"
              id="datePicker"
              className="form-control narrower-input"
              value={selectedDate.toISOString().split('T')[0]}
              onChange={(e) => handleDateChange(new Date(e.target.value))}
            />
          </Form.Group>
        </div>
        <div className="date-picker-button">
          <Button variant="outline-secondary" onClick={() => navigateDay(1)}>
            <span className="arrow-icon">→</span>
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-spinner">
          <Spinner animation="border" />
        </div>
      ) : hasAttendanceEntry ? (
        <Table striped bordered hover className="table-responsive mb-3"> {/* Hier wurde die "mb-3" Klasse hinzugefügt */}
          <thead>
            <tr>
              <th>Kind</th>
              <th>Typ</th>
              <th>Gruppe</th>
              <th>Anwesend</th>
              <th>Grund</th>
              <th>Kommentar</th>
              <th>Zuletzt gespeichert von</th>
              <th>Zuletzt gespeichert am</th>
            </tr>
          </thead>
          <tbody>
            {children.map(child => (
              <tr key={child.id}>
                <td>{child.firstname} {child.lastname}</td>
                <td>
                  {child.attendance_type === 'halfday' ? 'Halber Tag' : 'Ganzer Tag'}
                </td>
                <td>{getGroupNameById(child.groupRef._path.segments[3])}</td>
                <td>{child.isAttending ? 'Ja' : 'Nein'}</td>
                <td>{child.isAttending ? '' : (child.isSick ? 'Krank' : (child.inHoliday ? 'Ferien' : ''))}</td>
                <td>{child.comment}</td>
                <td>{child.lastEditedBy || ''}</td>
                <td>{new Date(child.lastEditedAt._seconds * 1000).toLocaleDateString('de-DE')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        ) : (
          <CalculateAttendance date={selectedDate} />
      )}
    </div>
  );
  
};

export default AttendanceList;