import React, { useState, useContext, useEffect } from 'react';
import ChildDetail from './ChildDetail';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import KitaContext from '../KitaContext';
import { auth } from '../firebase-config';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/ChildrenList.css';


const ChildrenList = () => {
  const [childrenData, setChildrenData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { selectedKita } = useContext(KitaContext);
  const [showModal, setShowModal] = useState(false);
  const [childData, setChildData] = useState({
    firstname: '',
    lastname: '',
    birthdate: '',
    address: '',
    zip: '',
    city: '',
    sex: '',
    kitaRef: `kita/${selectedKita.id}`,
    isActiveChild: false
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isGridView, setIsGridView] = useState(true);

  const [showChildDetails, setShowChildDetails] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth.currentUser) {
          const idToken = await auth.currentUser.getIdToken();
          const baseUrl = window.location.hostname === 'localhost' 
            ? 'http://localhost:5000' 
            : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
          //console.log(selectedKita);
          const response = await fetch(`${baseUrl}/children/kita/${selectedKita.id}`, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          });

          if (response.ok) {
            const data = await response.json();
            setChildrenData(data);
          } else {
            console.error('Error fetching children data');
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedKita]);

  const openModal = () => {
    setChildData({
      firstname: '',
      lastname: '',
      birthdate: '',
      address: '',
      zip: '',
      city: '',
      sex: '',
      kitaRef: `kita/${selectedKita.id}`,
      isActiveChild: false
    });
    setErrorMessage('');
    setShowModal(true);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChildData(prevState => ({ ...prevState, [name]: value }));
  };


  // Save a child
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const idToken = await auth.currentUser.getIdToken();
      const birthdateAsDate = new Date(childData.birthdate);
      const birthdateISO = birthdateAsDate.toISOString();

      const modifiedChildData = {
        ...childData,
        birthdate: birthdateISO
      };
      const baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:5000' 
      : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/child`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(modifiedChildData),
      });

      if (response.ok) {
        setSuccessMessage(`Das Kind ${childData.firstname} ${childData.lastname} wurde erfolgreich angelegt.`);
        setShowModal(false);
        // Refresh the children data
        const refreshResponse = await fetch(`${baseUrl}/children/kita/${selectedKita.id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
        if (refreshResponse.ok) {
          const refreshedData = await refreshResponse.json();
          setChildrenData(refreshedData);
        }
      } else {
        setErrorMessage('Speichern fehlgeschlagen. Bitte nochmals versuchen.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Speichern fehlgeschlagen. Bitte nochmals versuchen.');
    }
  };

  const filteredChildren = childrenData.filter((child) =>
    Object.values(child)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const renderGrid = () => {
    return (
      <div className="row justify-content-center">
        {filteredChildren.map((child, index) => (
          <div key={index} className="col-md-6 col-lg-3 mb-3">
            <Card className={child.isActiveChild ? 'child-card-active' : 'child-card-not-active'}>
              <Card.Body className="d-flex flex-column align-items-center">
                <Image
                  src={child.profilePic || 'placeholder-image-url'}
                  roundedCircle
                  className="child-profile-pic mb-3"
                />
                <Card.Title>{`${child.firstname} ${child.lastname}`}</Card.Title>
                <Button variant="primary" onClick={() => handleShowChildDetails(child.id)}>Details</Button>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    );
  };
  
  

  const renderTable = () => {
    return (
      <Table bordered striped hover>
      <thead>
        <tr>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Geburtsdatum</th>
          <th>Strasse</th>
          <th>PLZ</th>
          <th>Ort</th>
        </tr>
      </thead>
      <tbody>
        {filteredChildren.map((child, index) => (
          <tr key={index}>
            <td>{child.firstname}</td>
            <td>{child.lastname}</td>
            <td>{formatDate(child.birthdate)}</td>
            <td>{child.address}</td>
            <td>{child.zip}</td>
            <td>{child.city}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    );
  };

  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  const handleShowChildDetails = (childId) => {
    navigate(`/children/${childId}`)
  };
  

  const handleBackToChildrenList = () => {
    setShowChildDetails(false);
  };

  return (
    <>
      {showChildDetails ? (
        <ChildDetail onBack={handleBackToChildrenList} />
      ) : (
        <>
      <div className="container-md">
        {successMessage && (
          <div className="alert alert-success d-flex justify-content-between align-items-center">
            {successMessage}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setSuccessMessage('')}
            ></button>
          </div>
        )}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="search">Suche:</label>
            <input
              type="text"
              id="search"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-end">
            <button className="btn btn-primary" onClick={openModal}>
              Kind hinzufügen
            </button>
            <button
              className={`btn ${
                isGridView ? 'btn-outline-primary' : 'btn-primary'
              } ms-2`}
              onClick={toggleView}
            >
              <FontAwesomeIcon
                icon={isGridView ? faTable : faThLarge}
                className="me-2"
              />
              {isGridView ? 'Tabellenansicht' : 'Kachelansicht'}
            </button>
          </div>
        </div>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : isGridView ? (
          renderGrid()
        ) : (
          renderTable()
        )}
      </div>

    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Kind hinzufügen</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
    <Form onSubmit={handleSubmit}>
      <div className="row mb-3">
        {/* Vorname */}
        <Form.Group className="col-md-6">
          <Form.Label>Vorname</Form.Label>
          <Form.Control
            type="text"
            name="firstname"
            value={childData.firstname}
            onChange={handleInputChange}
          />
        </Form.Group>

        {/* Nachname */}
        <Form.Group className="col-md-6">
          <Form.Label>Nachname</Form.Label>
          <Form.Control
            type="text"
            name="lastname"
            value={childData.lastname}
            onChange={handleInputChange}
          />
        </Form.Group>
      </div>

      <div className="row mb-3">
        {/* Geburtsdatum */}
        <Form.Group className="col-md-4">
          <Form.Label>Geburtsdatum</Form.Label>
          <Form.Control
            type="date"
            name="birthdate"
            value={childData.birthdate || ''}
            onChange={handleInputChange}
          />
        </Form.Group>

        {/* Aktives Kind Toggle */}
        <Form.Group className="col-md-4 d-flex align-items-center">
          <Form.Check 
            type="switch"
            id="isActiveChild"
            label="Aktives Kind"
            name="isActiveChild"
            checked={childData.isActiveChild}
            onChange={(e) => setChildData(prevState => ({ ...prevState, isActiveChild: e.target.checked }))}
          />
        </Form.Group>
        
        {/* Biologisches Geschlecht */}
        <Form.Group className="col-md-4">
          <Form.Label>Biologisches Geschlecht</Form.Label>
          <Form.Control as="select" name="sex" value={childData.sex} onChange={handleInputChange}>
              <option value="">Bitte auswählen</option>
              <option value="m">männlich</option>
              <option value="w">weiblich</option>
          </Form.Control>
        </Form.Group>
      </div>

      <div className="row mb-3">
        {/* Strasse */}
        <Form.Group className="col-12">
          <Form.Label>Strasse</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={childData.address}
            onChange={handleInputChange}
          />
        </Form.Group>
      </div>

      <div className="row mb-3">
          {/* PLZ */}
          <Form.Group className="col-md-6">
            <Form.Label>PLZ</Form.Label>
            <Form.Control
              type="text"
              maxLength="4"
              name="zip"
              value={childData.zip}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Ort */}
          <Form.Group className="col-md-6">
            <Form.Label>Ort</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={childData.city}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>

        <Button type="submit">Speichern</Button>
      </Form>
    </Modal.Body>
  </Modal>
  </>
)}

  </>
  );
};

export default ChildrenList;
