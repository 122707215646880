import React, { useEffect, useState, useCallback  } from 'react';
import { Card, Modal, Button, Form, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth } from '../firebase-config';


const ChildFirstContact = ({ childId }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [parentData, setParentData] = useState(null);
  const [newParentInfo, setNewParentInfo] = useState({
    firstname: '',
    lastname: '',
    designation: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    sex: '',
    email: '',
    parentDocId: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  // Funktion zum Abrufen der Elterndaten
  const fetchParentsData = useCallback(async () => {
    try {
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

        const response = await fetch(`${baseUrl}/parents/${childId}`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });

        if (response.ok) {
          const parentData = await response.json();
          //console.log(parentData);
          setParentData(parentData);
        } else {
          console.error('Error fetching parents data');
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Setzen Sie isLoading auf false, wenn die Daten geladen wurden
    }
  }, [childId]);

  useEffect(() => {
    // Rufen Sie fetchParentsData auf, wenn die Komponente geladen wird
    fetchParentsData();
  }, [fetchParentsData]);

  const handleAdd = () => {
    setIsLoadingModal(true);
    setShowAddModal(true);
    setNewParentInfo({
    firstname: '',
    lastname: '',
    designation: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    sex: '',
    email: '',
    parentDocId: '',
  });
  setIsLoadingModal(false);
  };

  const handleEdit = (parent) => {
    // console.log('Edit Button geklickt');
    setShowEditModal(true);
    setIsLoadingModal(true);
    // Setzen Sie die zu bearbeitenden Daten in den State
    setNewParentInfo({
      firstname: parent.firstname,
      lastname: parent.lastname,
      designation: parent.designation,
      address: parent.address,
      zip: parent.zip,
      city: parent.city,
      country: parent.country,
      phone: parent.phone,
      sex: parent.sex,
      email: parent.email,
      parentDocId: getParentDocId(parent),
    });
    setIsLoadingModal(false);
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
  };

  // handles new entry end edited entry
  const handleSave = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      // Erstellen Sie die Anfrage-URL basierend auf der Aktion (Hinzufügen oder Bearbeiten)
      const actionUrl = showAddModal
        ? `/add-parents/${childId}`
        : `/update-parents/${childId}`;

      const response = await fetch(`${baseUrl}${actionUrl}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newParentInfo), // Den gesamten Request-Body senden
      });

      if (response.ok) {
        // Erfolgreiche Antwort, Daten wurden erfolgreich hinzugefügt/aktualisiert
        // Hier können Sie die Benutzeroberfläche aktualisieren oder andere Aktionen ausführen
        console.log('Daten erfolgreich gespeichert');
      } else {
        // Fehler beim Hinzufügen/Aktualisieren der Daten
        console.error('Fehler beim Speichern der Daten');
      }

      // Schließen Sie das Modal nach dem Speichern
      handleClose();
      // Rufen Sie hier fetchParentsData auf, um die Elterndaten neu abzurufen
      fetchParentsData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (parent) => {
    setShowDeleteModal(true);
    setNewParentInfo({
      parentDocId: getParentDocId(parent),
    });
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      const response = await fetch(`${baseUrl}/delete-parents/${childId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentDocId: newParentInfo.parentDocId }), // Übergeben Sie die parentDocId für das zu löschende Elternteil
      });

      if (response.ok) {
        console.log('Daten erfolgreich gelöscht');
        // Fügen Sie hier ggf. Code hinzu, um die Benutzeroberfläche zu aktualisieren
      } else {
        console.error('Fehler beim Löschen der Daten');
      }
      // Schließen Sie das Modal nach dem Löschen und nachdem der Ladevorgang abgeschlossen ist
      setShowDeleteModal(false);
      setIsLoadingDelete(false);

      fetchParentsData(); // Rufen Sie hier fetchParentsData auf, um die Elterndaten neu abzurufen
    } catch (error) {
      console.error(error);
    }
  };


  // helper for getting specific parentDocId
  const getParentDocId = (parent) => {
    return parent.parentDocId;
  };

  return (
    <div>
      <Card>
      <Card.Header>
      <Button variant="primary" className="me-2" onClick={() => handleAdd()}>
        <FontAwesomeIcon
          icon={faPlus}
        />
      </Button>
      </Card.Header>
      {isLoading ? ( // Zeigen Sie den Spinner an, wenn Daten geladen werden
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
) : (
  <div className="row">
    {parentData ? (
      parentData.map((parent, index) => (
        <div className="col-md-12" key={index}>
            <Card className="m-3">
              <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title>{parent.sex === 'm' ? 'Herr' : 'Frau'} {parent.firstname} {parent.lastname}</Card.Title>
                <div>
                          <div className="d-flex align-items-center">
                <Button variant="secondary" onClick={() => handleEdit(parent)}>
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="mr-2"
                  />
                </Button>
                <Button variant="danger" onClick={() => handleDelete(parent)}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mr-2"
                      />
                  </Button>
               </div>
               </div>
               </div>
                <Table striped borderless responsive>
                  <tbody>
                    <tr>
                      <td><strong>Bezeichnung:</strong></td>
                      <td>{parent.designation}</td>
                    </tr>
                    <tr>
                      <td><strong>Adresse:</strong></td>
                      <td>{parent.address}</td>
                    </tr>
                    <tr>
                      <td><strong>Ort:</strong></td>
                      <td>{parent.zip} {parent.city}, {parent.country}</td>
                    </tr>
                    <tr>
                      <td><strong>Telefon:</strong></td>
                      <td>{parent.phone}</td>
                    </tr>
                    <tr>
                      <td><strong>E-Mail:</strong></td>
                      <td>{parent.email}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
          ))
          ) : (
            <p>Es wurden keine Elterndaten gefunden.</p>
          )}
      </div>
      )}
      </Card>
      <Modal show={showAddModal || showEditModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showAddModal ? 'Neue Kontaktperson hinzufügen' : 'Kontaktperson bearbeiten'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isLoadingModal ? ( // Zeigen Sie den Spinner im Modal an, während die Daten geladen werden
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
          <Form>
          <Form.Group className="mb-3">
            <Form.Label>Bezeichnung</Form.Label>
            <Form.Control
              type="text"
              placeholder="z.B. Mutter"
              value={newParentInfo.designation}
              onChange={(e) => setNewParentInfo({ ...newParentInfo, designation: e.target.value })}
            />
          </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vorname"
                value={newParentInfo.firstname}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, firstname: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nachname"
                value={newParentInfo.lastname}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, lastname: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adresse"
                value={newParentInfo.address}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, address: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>PLZ</Form.Label>
              <Form.Control
                type="text"
                placeholder="PLZ"
                value={newParentInfo.zip}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, zip: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stadt</Form.Label>
              <Form.Control
                type="text"
                placeholder="Stadt"
                value={newParentInfo.city}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, city: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Land</Form.Label>
              <Form.Control
                type="text"
                placeholder="Land"
                value={newParentInfo.country}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, country: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telefon"
                value={newParentInfo.phone}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, phone: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Geschlecht</Form.Label>
              <Form.Select
                value={newParentInfo.sex}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, sex: e.target.value })}
              >
                <option value="">Bitte auswählen</option>
                <option value="m">Männlich</option>
                <option value="w">Weiblich</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-Mail"
                value={newParentInfo.email}
                onChange={(e) => setNewParentInfo({ ...newParentInfo, email: e.target.value })}
              />
            </Form.Group>
          </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Löschen-Bestätigungsmodal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bestätigen Sie das Löschen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isLoadingDelete ? ( // Zeigen Sie den Spinner, wenn isLoadingDelete true ist
          <Spinner animation="border" size="sm" role="status">
            <span className="visually-hidden">Löschen...</span>
          </Spinner>
        ) : (
          'Sind Sie sicher, dass Sie dieses Elternteil löschen möchten?' // Ansonsten anzeigen Sie die Schaltfläche "Löschen"
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChildFirstContact;
