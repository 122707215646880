import React, { useState } from 'react';
import './styles/LoginForm.css';

const ForgotPassword = ({ onBackToLogin, onResetPassword }) => {
  const [email, setEmail] = useState('');
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      // Hier rufst du die Funktion zum Zurücksetzen des Passworts auf
      await onResetPassword(email);
    } catch (error) {
      console.error('Reset password error:', error.message);
    }
  };

  return (
    <>
        <div className="card-body text-center">
          <form onSubmit={handleResetPassword}>
            <div className="mb-3">
              <input
                type="email"
                className="form-control text-center"
                placeholder="E-Mail-Adresse"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Passwort zurücksetzen
            </button>
            <p className="mt-3">
                <span
                onClick={onBackToLogin}
                className="link"
                style={{ cursor: 'pointer' }}>
                Zurück zum Login
              </span>
            </p>
          </form>
        </div>
        </>
  );
};

export default ForgotPassword;
